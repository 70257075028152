import {useState, useEffect, useContext} from 'react';
import {useCubeQuery} from '@cubejs-client/react';
import { Tab, Tabs } from 'react-bootstrap'
import { teammember_monthly} from '../kpi_queries/team_members';
import {dateUtilities} from '../utilities/date_formatting'

import { Context } from '../App';
import {BarChartFTEKPI, BarChartRevenue} from '../components/Graph/Istogram'
import { useTranslation } from 'react-i18next';
import { generateUUID } from '../utilities/ui_tools';

const rollingSum = (nums, fteMonth, shift) =>{
  let rollingYearRevenueFTE =[]
  let rollingFTE=[]
  let projectedYearlyRevenue=[]

  for(let i = nums.length - shift ; i < nums.length ; i++) {
    let totalMonth = nums[i]
    let totalFTE = fteMonth[i]
    for(let j = (shift-1) ; j > 0 ; j--) {
      totalMonth += nums[i-j-1]
      totalFTE += fteMonth[i-j-1]
    }
    rollingYearRevenueFTE.push(totalMonth);
    rollingFTE.push(totalFTE/ (shift/2))
    projectedYearlyRevenue.push(totalMonth * (totalFTE/ (shift/2)))
  }
   return [rollingYearRevenueFTE, rollingFTE,projectedYearlyRevenue]
}
export const KPITeamMember = ({kpi, daterange, title}) =>{
    const { t } = useTranslation();

    const [state, dispatch] = useContext(Context) //Gross Revenue Context

    const[data, setData] = useState()
    const {resultSet:monthlyKPI} = useCubeQuery(teammember_monthly(kpi,daterange, state.granularity))

    useEffect(() => {
      const shiftElements = state.granularity === "month" ? 24 : 8
        const parseResultSet = (monthlyKPI) =>{
          const labelSerie = []
          const grossSerie = []
          const referenceDateSerie = []

          const revenueFTESerie = []
          monthlyKPI.tablePivot().map((k) => {
            const objClass = Object.keys(k).map((i) => {
              return k[i];
            })
            const dateCurrent = new Date(objClass[0])
            if (dateCurrent.getTimezoneOffset() < 0){
              dateCurrent.setUTCDate(dateCurrent.getUTCDate()+1 );
            } else {
              dateCurrent.setUTCDate(dateCurrent.getUTCDate() + 1 );
            }
    
    

            labelSerie.push(dateUtilities.Quarters[dateCurrent.getUTCMonth()] + ' ' + (dateCurrent.getUTCFullYear()).toString())
            referenceDateSerie.push((dateCurrent.getUTCFullYear()).toString()  +  '-' + ('0' + (dateCurrent.getUTCMonth()+1).toString()).slice(-2) + '-01' )
            grossSerie.push(parseFloat(objClass[1]))
            revenueFTESerie.push(parseFloat(objClass[3]))          
            return objClass
          })
          const rollingValues = rollingSum(grossSerie,revenueFTESerie, shiftElements)
          return  [
            {label:"AVG Revenue",data:rollingValues[0],labels: labelSerie.slice(-shiftElements), references: rollingValues},
            {label:"Months",data:revenueFTESerie.slice(-shiftElements),dimension: labelSerie.slice(-shiftElements),references: referenceDateSerie.slice(-shiftElements)},
          ]
        }
        const dataParsed =(monthlyKPI)? parseResultSet(monthlyKPI): [];
        setData(dataParsed) ;
    },[monthlyKPI]);


    return <>
      {data ? <>
        <Tabs  defaultActiveKey="line-graph" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="line-graph" title={title}>
            {(data[0] && data[0].data) ?
              <BarChartRevenue 
              key={generateUUID(16)}
              formatLabel={true} 
              colorMono={true} 
              dataseries={[data[0]]} 
              title="" 
              Yaxis="$" 
              fitlerable={false}/> 
              :
              <></>
            }
          </Tab>
          <Tab eventKey="bar-graph" title={t('TABS.FTE')}>
            {(data[1] &&  data[1].data) ?
              <BarChartFTEKPI title='Revenue/FTE' dataseries={data[1]} colorMono={true}  Yaxis="n"/>
              :
              <></>
            }
          </Tab>
        </Tabs>
        </> :
        <div>Wait...</div>
      }
    </>
}