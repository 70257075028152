// Application Import
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';

// Context Import
import { Context } from '../../App'

// Hooks Import
import { useState, useContext, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next';

// Queries Import
import { customer_by, customers_count_by, all_customers_by } from '../../kpi_queries/customers'

// Internal Component Import
import { CustomersGrouped } from '../../components/CustomersGrouped'
import { ChurnRateHistoryWidget } from '../ChurnRateHistory';
import { ChurnRateWidget } from '../../components/ChurnRate'
import { CustomersCountWidget } from '../../components/CustomerCount'
import { LTVWidget } from '../../components/LTV';
// Formatting and Selector configuration import 
import { dateUtilities, lastDayMonth } from '../../utilities/date_formatting';
import { TitleCard, TitleSection } from '../../utilities/ui_tools'

import { customers_by_months_long } from '../../kpi_queries/customers';
import { KPIMonthlyView } from '../KPI_Monthly_View';
import { KPIMonthlySingleQuery } from '../KPI_Monthly_Single_Query';
// Cube Dev Import
import { CustomersTotalGrouped } from '../CustomersTotalGrouped';
import { QueryCubeRemote } from '../../common/APICalls/apiCalls';
import { referencePlusOneDay } from '../../utilities/date_formatting';

import { FormattedValue } from '../../common/FormattedValues/FormattedValues';

/**
* The component manage and display the KPI related to  Customers.  
* 
*/
const BaseCustomersSection = ({genericView}) => {

  const { t } = useTranslation()  //Initialize Translation related component.

  const [state, dispatch] = useContext(Context)                                /**General Context*/

  // Label sub-section definition and their update listener. t
  const [summaryLabelRange, setSummaryLabelRange] = useState(state.date_range.split(';'))
  const [trendChurnLabelRange, setTrendChrunLabelRange] = useState(state.data_series_date_interval)
  const [trendCustomersLabelRange, setTrendCusotmersLabelRange] = useState(state.data_series_date_interval)


  const [labelRiskRevenue,setLabelRiskRevenue] = useState('');
  const [labelRiskNumbers, setLabelRiskNumbers] = useState('')
  
  //Define churn parameters object and his state function updater. This object is used to be rendered   
  const [churnParameters, setChurnParameters] = useState({
    period: [state.data_series_date_interval, state.data_series_date_interval_prior],
    type: "trailing",
    granularity: state.granularity,
    index: '0',
    field: 'CustomersTotal.yearlyCount'
  })

  /**
   * Function to update the churn rate state parameter. When changed the related widget are re-rendered.  
   * @param {*} lookback churn rate lookback period 
   * @param {*} granularity churn rate granularity detail 
   */
  const renderChurnTrend = (lookback, granularity) => {

    if (lookback === 12 && granularity === 'quarter') {
      setChurnParameters({
        period: [state.data_series_date_interval, state.data_series_date_interval_prior],
        type: "trailing",
        granularity: "quarter",
        index: '0',
        field: 'CustomersTotal.yearlyCount'
      })
    } else if (lookback === 3 && granularity === 'quarter') {
      setChurnParameters({
        period: [state.data_series_date_interval, state.data_series_date_interval_prior],
        type: "puntual",
        granularity: "quarter",
        index: '1',
        field: 'CustomersTotal.quarterCount'
      })
    } else if (lookback === 12 && granularity === 'month') {
      setChurnParameters({
        period: [state.data_series_date_interval, state.data_series_date_interval_prior],
        type: "trailing",
        granularity: "month",
        index: '2',
        field: 'CustomersTotal.yearlyCount'
      })
    } else if (lookback === 1 && granularity === 'month') {
      setChurnParameters({
        period: [state.data_series_date_interval, state.data_series_date_interval_prior],
        type: "puntual",
        granularity: "month",
        index: '3',
        field: 'CustomersTotal.monthCount'
      })
    }
  }

  const resultByRisk =  QueryCubeRemote({
    "measures": [
      "MnEvents.revenue_flag",
      "MnEvents.risk_numbers",
      
    ],
    "timeDimensions": [
      {
        "dimension": "MnEvents.timestamp",
        "dateRange": [
          "2024-07-01",
          "2024-06-30"
        ]
      }
    ]
  }) 

  useEffect (() => {
    const parseResultSet =(resultByRisk) => {
      renderChurnTrend(state.lookback, state.granularity)
      const resultField = resultByRisk.tablePivot().map((k) => {
        const objClass = Object.keys(k).map((i) => {
          return k[i]
        })
        return [
          <FormattedValue type="money" value={parseInt(objClass[0])/100} />,
          <FormattedValue type="absolute" value={parseInt(objClass[1])} />
      
        ]
        })
      return resultField
    } 
    const dataParsed =resultByRisk ? parseResultSet(resultByRisk): ['',''];
    setLabelRiskRevenue(dataParsed[0][0])
    setLabelRiskNumbers(dataParsed[0][1])

  },[resultByRisk])
  // Here is retruned the rendered JSX component

  return <div>


    {/* Summary Main Card */}
    <Card id="customers" className="card_section">
      <TitleCard
        label={t('SECTION.CUSTOMERS.SUMMARY')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />
      <Card.Body className='card-height-xs'>
        <Row xs={1} md={2} lg={4} className="g-5 car row_kpi">

          {/* Actives Customer Card */}
          <Col className="col_kpi">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.ACTIVE_CUSTOMERS')}</Card.Title>
              <Card.Body>
                <CustomersCountWidget />
              </Card.Body>
            </Card>
          </Col>

          {/* ARPU Card */}
          <Col className="col_kpi">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.ARPU')}</Card.Title>
              <Card.Body>
                <LTVWidget kpi={'arpu'} lookback={state.lookback}/>
              </Card.Body>
            </Card>
          </Col>


          {/* Churn Rate Card */}
          <Col className="col_kpi col_kpi_central">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.CHURN_RATE')}</Card.Title>
              <Card.Body>
                <ChurnRateWidget />
              </Card.Body>
            </Card>
          </Col>

          {/* LTV Card */}
          <Col className="col_kpi">
            <Card className="card_kpi">
              <Card.Title>{t('KPIs.TITLES.LTV')}</Card.Title>
              <Card.Body>
                <LTVWidget kpi={'ltv'}  lookback={state.lookback}/>
              </Card.Body>

            </Card>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {state.lookback === 1 ?
          <Trans
            i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_MONTHTRAIL"
            t={t}
            components={{
              DATE_START: dateUtilities.monthsNamesShort[new Date(summaryLabelRange[1]).getUTCMonth()] + ' ' + (new Date(summaryLabelRange[1]).getUTCFullYear()),
            }}
          />
          :
          <Trans
            i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS_CUSTOMER"
            t={t}
            components={{
              DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(summaryLabelRange[0]).getUTCMonth()] + ' ' + (new Date(summaryLabelRange[0]).getUTCFullYear()),
              DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
              REFERENCE_DATE: process.env.REACT_APP_HR_REFERENCE_DATE
            }}
          />
        }
      </Card.Footer>
    </Card>

  {/* Customer Trend Card */}
  <Card className = "card_section">

    <TitleCard  label = {t('SECTION.CUSTOMERS.TREND')} 
                date_end = {process.env.REACT_APP_HR_REFERENCE_DATE}
    />

    <Card.Body  className = 'card-height-m'>
      <KPIMonthlySingleQuery 
        granularity = {state.granularity} 
        queryTrend = {customers_by_months_long([state.data_series_date_interval_prior[0],state.data_series_date_interval[1]],state.granularity)} 
        type = '0' 
        keyStorage = 'customer_trend'
        Yaxis="Customer Number"
        reduce={true}
        filterable={false}
      />
    </Card.Body>

    <Card.Footer className = 'card-footer-muted'>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
                t = {t}
                components = {{ DATE_START:'01 ' + dateUtilities.monthsNamesShort[new Date(trendCustomersLabelRange[0]).getUTCMonth()] + ' ' + (new Date(trendCustomersLabelRange[0]).getUTCFullYear()),
                                DATE_END:  process.env.REACT_APP_HR_REFERENCE_DATE,
                }} 
        />
      <br/>
    </Card.Footer>

  </Card>

   
    {/* Customer by Cohort Card */}
    {!genericView ?
    <Card className="card_section">

      <TitleCard label={t('SECTION.CUSTOMERS.COHORT')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-s'>
        <CustomersGrouped queryToRender={customer_by(['Customers.id_cohort','Customers.cohort'], process.env.REACT_APP_REFERENCE_DATE,{"Customers.id_cohort": "asc"})}
          splitUs={false}
          totalUsers={true}
          labelPosition={1}
          valuePosition={2}
          keyStorage='Customer_Cohort' />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br />
        <Trans i18nKey="KPIs.LABELS_AND_FOOTERS.FOOTER_COHORT"
          t={t}
          components={{
            MONTHS: state.lookback.toString(),
            UNIT: state.lookback === 1 ? 'month' : 'months'
          }}
        />

      </Card.Footer>
    </Card>
    :<></>}

    {/* Customer by Status */}
    {!genericView ?
    <Card className="card_section">

      <TitleCard label={t('SECTION.CUSTOMERS.STATUSCARD')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-s'>
        <CustomersGrouped queryToRender={all_customers_by('Customers.contractStatus', process.env.REACT_APP_REFERENCE_DATE,'canceled')}
                splitUs={false}
                totalUsers={true}
                labelPosition={0}
                valuePosition={1}
      
                keyStorage='Customer_Status'
              />  
     </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
      </Card.Footer>
    </Card>
    :<></>}

    {/* Customer by Country Card */}
    {!genericView ?
    <Card className="card_section">

      <TitleCard label={t('SECTION.CUSTOMERS.COUNTRY')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      <Card.Body className='card-height-xl'>
        <CustomersGrouped queryToRender={customer_by(['Customers.country'], process.env.REACT_APP_REFERENCE_DATE)}
          splitUs={true}
          totalUsers={true}
          labelPosition={0}
          valuePosition={1}

          keyStorage='Customer_Country'
        />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br/>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.CREDIT_CARD"
                t={t}
                components = {{ 
                  MONTHS:state.lookback.toString(),
                  UNIT:state.lookback === 1 ? 'month' : 'months'
                }} 
        />

      </Card.Footer>

    </Card>
    :<></>}


    {/* Customer by Flags */}
    {!genericView ?
    <Card className="card_section">
      <TitleCard label={t('SECTION.CUSTOMERS.BY') + t('SECTION.CUSTOMERS.FLAGS')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />

      {/* Customer by Governnment Flags */}
      <Card.Body className="row">
        <Row>
          <Col className='col_kpi_mid'>
            <Card className="card_kpi">
              <Card.Title>{t('SECTION.CUSTOMERS.FLAG_GOVERNMENT')}</Card.Title>
              <Card.Body>
              <CustomersGrouped queryToRender={customer_by(['Customers.flagGovernment'], process.env.REACT_APP_REFERENCE_DATE)}
                splitUs={false}
                totalUsers={true}
                labelPosition={0}
                valuePosition={1}
      
                keyStorage='Customer_Government'
              />
              </Card.Body>
            </Card>

          </Col>
          <Col  className='col_kpi_mid'>
          {/* Customer by Embargoed Flag */}
          <Card className="card_kpi">
            <Card.Title>{t('SECTION.CUSTOMERS.FLAG_EMBARGOED')}</Card.Title>
            <Card.Body>
              <CustomersGrouped queryToRender={customer_by(['Customers.flagEmbargoed'], process.env.REACT_APP_REFERENCE_DATE)}
                splitUs={false}
                totalUsers={true}
                labelPosition={0}
                valuePosition={1}
      
                keyStorage='Customer_Embargoed'
              />
            </Card.Body>
          </Card>
          </Col>
          <Col className='col_kpi_mid'>
            {/* Customer by Politically Exposed Flag */}
            <Card className="card_kpi">
                <Card.Title>{t('SECTION.CUSTOMERS.FLAG_POLITICALLY_EXPOSED')}</Card.Title>
                <Card.Body>
                <CustomersGrouped queryToRender={customer_by(['Customers.flagPoliticallyExposed'], process.env.REACT_APP_REFERENCE_DATE)}
                  splitUs={false}
                  totalUsers={true}
                  labelPosition={0}
                  valuePosition={1}
        
                  keyStorage='Customer_Politically_Exposed'
                />
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t(
          'KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.<br/>
          12-month trailing revenue for <b>{labelRiskNumbers}</b> customers with one or more risk flags: <b>{labelRiskRevenue}</b>.
      </Card.Footer>

    </Card>
    :<></>}

    {/* Churn Rate Trend Card */}
    {!genericView ?
      <Card className="card_section">
        <TitleCard label={t('SECTION.CUSTOMERS.CHURN_TREND')}
          date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
        />

        <Card.Body className='card-height-m'>
          {/* Churn Rate History Widget */}
          <ChurnRateHistoryWidget 
            periodToShow={churnParameters.period}
            type={churnParameters.type}
            granularity={state.granularity}
            lookback={churnParameters.lookback}
            field={churnParameters.field} />

            
        </Card.Body>
        <Card.Footer className='card-footer-muted'>
            <Trans
              i18nKey="KPIs.LABELS_AND_FOOTERS.LAST_TOTMONTHS"
              t={t}
              components={{
                DATE_START: '01 ' + dateUtilities.monthsNamesShort[new Date(trendChurnLabelRange[0]).getUTCMonth()] + ' ' + (new Date(trendChurnLabelRange[0]).getUTCFullYear()),
                DATE_END: process.env.REACT_APP_HR_REFERENCE_DATE,
              }}
            />
          <br />

          {t('KPIs.LABELS_AND_FOOTERS.DATA_ANNUALIZED')}
        </Card.Footer>
      </Card>
    : <></>}

     {/* Churn Rate by Location Card */}
    
     {!genericView ?
    <Card className="card_section">

      <TitleCard label={t('SECTION.CUSTOMERS.CHURN_RATE_BY_LOCATION')} date_end={process.env.REACT_APP_HR_REFERENCE_DATE} />

      <Card.Body className='card-height-xs'>
        <CustomersTotalGrouped title={t('KPIs.TITLES.BY_LOCATION')} 
        queriesToRender={[
          customers_count_by(["CustomersTotal.location"],"CustomersTotal.cancellation",null, [state.date_range.split(';')[0],referencePlusOneDay(state.date_range.split(';')[1]),]),
          customers_count_by(["CustomersTotal.location"],null, "CustomersTotal.cancellation",[state.date_range.split(';')[0],referencePlusOneDay(state.date_range.split(';')[1]),])
        ]}
        itemsLine={2} />
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br/>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.CREDIT_CARD"
                t={t}
                components = {{ 
                  MONTHS:state.lookback.toString(),
                  UNIT:state.lookback === 1 ? 'month' : 'months'
                }} 
        />
      </Card.Footer>
    </Card>
    :<></>}
   
    {/* Churn Rate Cohort Card */}
    {!genericView ?
    <Card className="card_section">

      <TitleCard label={t('SECTION.CUSTOMERS.CHURN_RATE_BY_COHORT')}
        date_end={process.env.REACT_APP_HR_REFERENCE_DATE}
      />
      <Card.Body className='card-height-xs'>
        <CustomersTotalGrouped title={t('KPIs.TITLES.BY_COHORT')} 
          queriesToRender={[
          customers_count_by(["CustomersTotal.id_cohort","CustomersTotal.customerCohort"],"CustomersTotal.cancellation",null, [state.date_range.split(';')[0],referencePlusOneDay(state.date_range.split(';')[1]),],{"CustomersTotal.id_cohort": "asc"}),
          customers_count_by(["CustomersTotal.id_cohort","CustomersTotal.customerCohort"], null,"CustomersTotal.cancellation",[state.date_range.split(';')[0],referencePlusOneDay(state.date_range.split(';')[1]),],{"CustomersTotal.id_cohort": "asc"}),
          ]} 
          itemsLine={3}/>
      </Card.Body>

      <Card.Footer className='card-footer-muted'>
        {t('KPIs.LABELS_AND_FOOTERS.FOOTER_ATDATE')}{process.env.REACT_APP_HR_REFERENCE_DATE}.
        <br/>
        <Trans  i18nKey = "KPIs.LABELS_AND_FOOTERS.FOOTER_COHORT"
                t={t}
                components = {{ 
                  MONTHS:state.lookback.toString(),
                  UNIT:state.lookback === 1 ? 'month' : 'months'
                }} 
        />
     </Card.Footer>
    </Card>
    : <></>}


  </div>
}

export const CustomersSection = React.memo(BaseCustomersSection) 