import { useState, useEffect, useContext } from 'react'
import { useCubeQuery } from '@cubejs-client/react'
import { customers_lost_period, customers_lost_period_w_granularity_kindofcount, customers_lost_period_monthly, count_customers, count_customers_granular } from '../kpi_queries/customers'
import { customers_actives_start_parameters } from '../kpi_queries/customers';

import { AVGOrder } from '../kpi_queries/ltv'
import { Context } from '../App';

import { quarterOrMonth } from '../utilities/date_formatting';

export const ChurnRatePeriod = (query_to_render) => {
    const [data, setData] = useState()
    const { resultSet: activeUsers } = useCubeQuery(query_to_render)
    const { resultSet: usersLost } = useCubeQuery(customers_lost_period_monthly([process.env.REACT_APP_DATERANGE.split(';'), process.env.REACT_APP_DATERANGE_PRIOR.split(';')]))

    useEffect(() => {
        const parseResultSet = (usersLost, activeUsers) => {
            const totalCustomers = activeUsers.tablePivot().map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            })
            const lostCustomers = usersLost.tablePivot().map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            })
            const churnRatesArrays = totalCustomers.map((totalCustomer, index) => {
                const lostCustomer = lostCustomers[index]
                return {
                    label: lostCustomer[0],
                    churnPercent_woG_prev: (lostCustomer[1] / totalCustomer[1]) * 100,
                    churnPercent_woG_prior: (lostCustomer[2] / totalCustomer[2]) * 100,
                    lostcustomers: parseInt(lostCustomer[1])
                }
            })
            return churnRatesArrays
        }
        const dataParsed = (usersLost && activeUsers) ? parseResultSet(usersLost, activeUsers) : null
        setData(dataParsed)
    }, [activeUsers, usersLost])
    return data
}

export const ChurnRateParameters = (period, type, granularity, field) => {

    const [data, setData] = useState()
    const { resultSet: activeUsers } = useCubeQuery(customers_actives_start_parameters([process.env.REACT_APP_REFERENCE_START, period[0][1]], granularity))
    const { resultSet: usersLost } = useCubeQuery(customers_lost_period_w_granularity_kindofcount([period[1][0], period[0][1]], granularity, field))

    const defineObjectArray = (originalArray) => {
        const half = Math.floor(originalArray.length / 2);
        const secondSerie = originalArray.slice(-half)
        const firstSerie = originalArray.slice(originalArray.length - (half * 2), -half)
        let aggregatedResult = []

        for (let i = 0; i < firstSerie.length; i++) {
            aggregatedResult.push([secondSerie[i][0], secondSerie[i][1], firstSerie[i][0], firstSerie[i][1]])
        }
        return aggregatedResult
    }

    useEffect(() => {
        const parseResultSet = (usersLost, activeUsers) => {
            const lostCustomers = usersLost.tablePivot({ fillMissingDates: true }).map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            })
            const totalCustomers = activeUsers.tablePivot({ fillMissingDates: true }).map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            }).slice(-lostCustomers.length)
            const aggragatedCustomers = defineObjectArray(totalCustomers)
            const aggragatedLostCustomers = defineObjectArray(lostCustomers)


            const parsedData = aggragatedCustomers.map((item, index) => {
                return {
                    churnPercent_woG_prev: (aggragatedLostCustomers[index][1] / item[1]) * 100,
                    churnPercent_woG_prior: (aggragatedLostCustomers[index][3] / item[3]) * 100,
                    lostcustomers: parseInt(aggragatedLostCustomers[index][1]),
                    lostCustomersPrior: parseInt(aggragatedLostCustomers[index][3]),
                    totalCustomers: parseInt(item[1]),
                    totalCustomersPrior: parseInt(item[3]),
                    period: item[0],
                    period_prior: item[2],

                }
            })
            return parsedData
        }
        const dataParsed = (usersLost && activeUsers) ? parseResultSet(usersLost, activeUsers) : null
        setData(dataParsed)
    }, [activeUsers, usersLost])
    return data
}



export const ChurnRateGranular = (dateRange, granularity, lookback) => {
    const [data, setData] = useState()
    const { resultSet: customers } = useCubeQuery(count_customers_granular(
        ['CustomersKpi.date', 'CustomersKpi.start', 'CustomersKpi.lost', 'CustomersKpi.churn_rate'],
        dateRange,
        granularity,
        lookback
    ))

    useEffect(() => {
        if (!customers) {
            return;
        }
        let total = customers.tablePivot({
            fillMissingDates: true
        })
        if(granularity === 'quarter'){
            total = total.filter((x) => {
              const d = new Date(x[0].replace('T', ' '));
              if (d.getTimezoneOffset() < 0){
                d.setUTCDate(d.getUTCDate()+1 );
              } else {
                d.setUTCDate(d.getUTCDate() + 1 );
              }
              return [2, 5, 8, 11].includes(d.getUTCMonth())
  
            })
        }
        const half = Math.ceil(total.length / 2);
        setData({
            prev: total.slice(0, half),
            prior: total.slice(-half),
        })
    }, [customers])

    return data
}

export const ChurnRateSimple = (dateRange, granularity, lookback) => {
    const [data, setData] = useState()
    const { resultSet: customers } = useCubeQuery(count_customers(
        ['CustomersKpi.lost', 'CustomersKpi.churn_rate'],
        dateRange,
        granularity,
        lookback
    ))

    useEffect(() => {
        if (!customers) {
            return;
        }
        const pivot = customers.tablePivot();
        if (pivot && pivot.length > 0){
          setData({
            churn: parseFloat(pivot[0]['CustomersKpi.churn_rate']),
            lost: parseInt(pivot[0]['CustomersKpi.lost']),
        })

        }
    }, [customers])

    return data
}

export const ChurnRate = (query_to_render) => {
    const [data, setData] = useState()
    const [state] = useContext(Context)

    let field = ''
    let multiplier = 1
    switch (state.summary_lookback) {
        case 1:
            field = "CustomersTotal.monthCount"
            multiplier = 12
            break
        case 3:
            field = "CustomersTotal.quarterCount"
            multiplier = 4
            break
        case 6:
            field = "CustomersTotal.semesterCount"
            multiplier = 2
            break
        default:
            field = "CustomersTotal.yearlyCount"
            break
    }
    const { resultSet: activeUsers } = useCubeQuery(query_to_render)
    const { resultSet: usersLost } = useCubeQuery(customers_lost_period([state.date_range.split(';'), state.date_range_prior.split(';')], field))

    useEffect(() => {
        const parseResultSet = (usersLost, activeUsers) => {
            const totalCustomers = activeUsers.tablePivot({ fillMissingDates: true }).map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            })
            const lostCustomers = usersLost.tablePivot({ fillMissingDates: true }).map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i];
                });
                return objClass
            })
            return {
                churnPercent_woG_prev: (lostCustomers[0][0] / totalCustomers[0][0]) * 100 * multiplier,
                churnPercent_woG_prior: (lostCustomers[0][1] / totalCustomers[0][1]) * 100 * multiplier,
                lostcustomers: parseInt(lostCustomers[0][0]),
                lostcustomersPrior: parseInt(lostCustomers[0][1]),
                totalCustomers: parseInt(totalCustomers[0][0]),
                totalCustomersPrior: parseInt(totalCustomers[0][1])
            }
        }
        const dataParsed = (usersLost && activeUsers) ? parseResultSet(usersLost, activeUsers) : null
        setData(dataParsed)
    }, [activeUsers, usersLost])
    return data
}

export const GrossLTV = (dateRange) => {
    const [data, setData] = useState([])
    const { resultSet: LTV } = useCubeQuery(AVGOrder(dateRange))

    useEffect(() => {
        const parseResultSet = (LTV) => {
            return parseInt(LTV.tablePivot()[0]['CustomerRPU.ltv'])
        }
        const dataParsed = [LTV ? parseResultSet(LTV) : []]
        setData(dataParsed)
    }, [LTV])
    return data
}

export const CustomersTotal = (dateRange, granularity, lookback) => {

    const [data, setData] = useState()
    const { resultSet: customers } = useCubeQuery(count_customers(['CustomersKpi.end', 'CustomersKpi.start'], dateRange, granularity, lookback))

    useEffect(() => {
        if (!customers) {
            return;
        }
        const pivot = customers.tablePivot();
        if (pivot && pivot.length > 0){
          setData({
            prior:parseInt(pivot[0]['CustomersKpi.start']),
            prev: parseInt(pivot[0]['CustomersKpi.end']),
        })

        }
    }, [customers])

    return data
}

export const AverageCustomerValue = (dateRange) => {
    const [data, setData] = useState([])
    const { resultSet: LTV } = useCubeQuery(AVGOrder(dateRange))

    useEffect(() => {
        const parseResultSet = (LTVvalue) => {
            return parseFloat(LTVvalue.tablePivot()[0]['CustomerRPU.avg_order'])
        }
        const dataParsed = [LTV ? parseResultSet(LTV) : []]
        setData(dataParsed)
    }, [LTV])
    return data
}

export const QueryPeriod = (period, query, kpi) => {
    const [data, setData] = useState()
    const { resultSet: TotalPeriod } = useCubeQuery(query(period, kpi))
    useEffect(() => {
        const parseResultSet = (Revenue) => {
            return Revenue.tablePivot().map((k) => {
                const objClass = Object.keys(k).map((i) => {
                    return k[i]
                })
                return objClass
            })
        }
        const dataParsed = TotalPeriod ? parseResultSet(TotalPeriod) : []
        setData(dataParsed[0])
    }, [TotalPeriod])
     return data
}


export const QuerySummaryPeriod = (period, query, kpi,granularity) => {
  const [data, setData] = useState()
  const { resultSet: TotalPeriod } = useCubeQuery(query(period, kpi,granularity))
  
  useEffect(() => {
      const parseResultSet = (Revenue) => {
          return Revenue.tablePivot().map((k) => {
              const objClass = Object.keys(k).map((i) => {
                  return k[i]
              })
              return objClass
          })
      }
      const dataParsed = TotalPeriod ? parseResultSet(TotalPeriod) : []
        // Determine month number
        const referenceDate = new Date(process.env.REACT_APP_REFERENCE_DATE)
        
       const lastMonthts = dataParsed.slice(-(referenceDate.getUTCMonth()+1))
        const ytdSummary = lastMonthts.reduce((acc, curr) => {
          return {
            previous: acc.previous + (Number(curr[1])/100),
            prior: acc.prior + (Number(curr[2])/100),
          };
        }, {
          previous: 0,
          prior: 0
        });

        const trailingArray = dataParsed.slice(-(dataParsed.length/2))

        let  trailingSummary = {
          previous: 0,
          prior: 0
        }
        trailingArray.forEach((element,index) => {
          trailingSummary.previous = trailingSummary.previous + Number(element[1])/100
          trailingSummary.prior = trailingSummary.prior +  Number(dataParsed[index][1])/100
        })

        const trendLine = dataParsed.reduce((acc, curr) => {
          acc.data.push( (Number(curr[1]/100)))
          acc.labels.push(quarterOrMonth(curr[0],'month'))
          return acc        
        }, {
          label:"Months", 
          data: [], 
          labels:[]
        }

        );

      setData({detail: dataParsed,trendLine:trendLine, trailing:trailingSummary, ytd: ytdSummary })
  }, [TotalPeriod])
  return data
}