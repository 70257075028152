import { useState, useEffect } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import Table from 'react-bootstrap/Table'
import { FormattedValue } from '../common/FormattedValues/FormattedValues';
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FullDoughnuthKPI } from './Graph/FullDoughnuth'
import { groupValues } from '../utilities/array_manipulation'
import { useTranslation } from 'react-i18next';

export const CustomersGrouped = ({ queryToRender, splitUs, totalUsers,labelPosition,valuePosition,keyStorage }) => {

  const [data, setData] = useState()
  const [totalValue, setTotal] = useState(0)

  const { resultSet: usersByMeasure } = useCubeQuery(queryToRender);

  const { t } = useTranslation();

  useEffect(() => {
    const parseResultSet = (usersByMeasure, totalUsers) => {
      let total = 0

      const measureData = usersByMeasure.tablePivot().map((k) => {
        const objClass = Object.keys(k).map((i) => {
          return k[i];
        });
        total += parseInt(objClass[valuePosition])
        const rawLabel = typeof objClass[labelPosition] === "number" ? Boolean(objClass[labelPosition])  : objClass[labelPosition] ?? 'N/A'
        const stringifiedLabel = String(rawLabel)
        return [stringifiedLabel.charAt(0).toUpperCase() + stringifiedLabel.slice(1), objClass[valuePosition], total]
      });

      if (totalUsers && totalUsers !== true) {
        total = Object.keys(totalUsers.tablePivot()[0]).map(function (key) {
          let numberusers = totalUsers.tablePivot()[0][key];
          return numberusers
        })[0];
      }
      setTotal(total)
      return groupValues(measureData, parseInt(total), splitUs ,'absolute',false, null, keyStorage)
    };

    const dataParsed = (usersByMeasure && totalUsers) ? parseResultSet(usersByMeasure, totalUsers) : null;
    setData(dataParsed)
  }, [totalUsers, usersByMeasure, splitUs]);


  return <>
    {splitUs ?
      <>
        {data ?
          <>
            <Row md={2} lg={2} className="g-5 car row_kpi">
              <Col className="col_kpi">
                <Card className="card_kpi">
                  <Card.Title>{t('KPIs.TITLES.TOTAL')}</Card.Title>
                  <Card.Body>
                    <div className='chart-md'>
                      <FullDoughnuthKPI values={data[1][0]} labelsIn={data[0][0]} />
                    </div>
                    <Table bordered striped>
                      <thead>
                        <tr>
                          <th className='cell-emphasis'></th>
                          <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.NUMBER')}</th>
                          <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[2][0]}
                        <tr className='row-summary'>
                          <td className="cell-label">{t('TABLES.END_ROW.TOTAL')}</td>
                          <td className="cell-value"><FormattedValue type="absolut" value={parseInt(totalValue)} /></td>
                          <td className="cell-value"><FormattedValue type="percent" value={100} /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col_kpi">
                <Card className="card_kpi">
                  <Card.Title>{t('KPIs.TITLES.COUNTRIES_NON_US')}</Card.Title>
                  <Card.Body>
                    <div className='chart-md'>
                      <FullDoughnuthKPI values={data[1][1]} labelsIn={data[0][1]} />
                    </div>
                    <Table bordered striped>
                      <thead>
                        <tr>
                          <th className='cell-emphasis'></th>
                          <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.NUMBER')}</th>
                          <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data[2][1]}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Container fluid>
              <Row id="Graph">
              </Row>
              <Row id="Tables">
                <Col md={6}>
                </Col>
              </Row>
            </Container>
          </>
          :
          <></>
        }
      </>
      :
      <Container>
        {data ?
          <Row>
            <Col>
              <div className='chart-md'>
                <FullDoughnuthKPI values={data[1][0]} labelsIn={data[0][0]}  keyStorage={keyStorage} />
              </div>
            </Col>
            <Col>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th className='cell-emphasis'></th>
                    <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.NUMBER')}</th>
                    <th className='cell-emphasis cell-value'>{t('TABLES.ROW_HEADERS.PERCENT')}</th>
                  </tr>

                </thead>
                <tbody>
                  {data[2][0]}
                  <tr className="row-summary">
                    <td className="cell-label">{t('TABLES.END_ROW.TOTAL')}</td>
                    <td className="cell-value"><FormattedValue type="absolut" value={parseInt(totalValue)} /></td>
                    <td className="cell-value"><FormattedValue type="percent" value={100} /></td>
                    <td></td>
                  </tr>

                </tbody>
              </Table>
            </Col>
          </Row>
          :
          <></>
        }
      </Container>

    }
  </>
};
