
export const customer_total_distinct = (compareRange) => {
  return {
    "measures": [
      "CustomersDistinct.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersDistinct.created_at",
        "compareDateRange": compareRange
      }
    ],
    "order": {
      "CustomersDistinct.created_at": "asc"
    },
    "filters": [],
    "dimensions": []
  }
}
export const customers_total = (compareRange) => {
  return {
    "measures": [
      "CustomersActives.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersActives.created_at",
        "compareDateRange": compareRange
      }
    ],
    "order": {
      "CustomersActives.created_at": "asc"
    },
    "filters": [],
    "dimensions": []
  }
}
export const customers_by_months = (compareRange, granularity) => {
  return {
    "measures": ["CustomersActives.count"],
    "timeDimensions": [
      {
        "dimension": "CustomersActives.created_at",
        "granularity": granularity,
        "compareDateRange": compareRange
      }
    ],
    "order": {
      "CustomersActives.created_at": "asc"

    },
    "filters": [
    ]
  }
}

export const customers_by_months_long = (dateRange, granularity) => {
  const query = {
    "dimensions": ["CustomersKpi.end"],
    "timeDimensions": [
      {
        "dimension": "CustomersKpi.date",
        "granularity": "month",
        "dateRange": dateRange
      }
    ],
    "order": {
      "CustomersKpi.date": "asc"
    }
  }
  return query
}

export const count_customers = (dimensions, referenceDates, granularity, lookback) => {
  const query = {
    "dimensions":  dimensions,
    "filters": [
      {
        "member": "CustomersKpi.date",
        "operator": "equals",
        "values": referenceDates
      },
      {
        "member": "CustomersKpi.granularity",
        "operator": "equals",
        "values": [granularity],
      },
      {
        "member": "CustomersKpi.lookback",
        "operator": "equals",
        "values": [lookback],
      },
    ],
    "order": {
      "CustomersKpi.date": "desc"
    }
  }
  return query
}

export const count_customers_granular = (dimensions, referenceDates, granularity, lookback) => {
  return {
    "dimensions":  dimensions,
    "filters": [
      {
        "member": "CustomersKpi.date",
        "operator": "inDateRange",
        "values": referenceDates
      },
      {
        "member": "CustomersKpi.granularity",
        "operator": "equals",
        "values": [granularity],
      },
      {
        "member": "CustomersKpi.lookback",
        "operator": "equals",
        "values": [lookback],
      },
    ],
    "order": {
      "CustomersKpi.date": "asc"
    }
  }
}

export const all_customers_by = (dimension, referenceDate,exclusion) => {

  const query =  {
    "dimensions": [
      dimension
    ],
    "measures": [
      "Customers.count"
    ],
    "filters": [
      {
        "member": "Customers.activationDate",
        "operator": "beforeDate",
        "values": [referenceDate]
      },
    ],
    "order": {
      "Customers.count": "desc"
    }
  }

  if (exclusion){
    query.filters.push(
      {
        "member": dimension,
        "operator": "notContains",
        "values":[exclusion]
      }
    )
  }
  return query
}

export const customer_by = (dimensions, referenceDate,order) => {
   const query = {
    "dimensions":  dimensions
    ,
    "measures": [
      "Customers.count"
    ],
    "filters": [
      {
        "member": "Customers.activationDate",
        "operator": "beforeDate",
        "values": [referenceDate]
      },
    ],
    "order": {
      "Customers.count": "desc"
    }
  }
  if (order) {
    query.order = order
  }
  return query
}

export const customers_count_by = (dimensions,filter, timeDimension,timeRange,order) => {

  const query = {
    "dimensions": dimensions
    ,
    "measures": [
      "CustomersTotal.count"
    ],
    "order": {
      "CustomersTotal.count": "desc"
    }
  }
  if (filter) {
    query.filters = [
      {
        "member": filter,
        "operator": "gte",
        "values": [
          timeRange[0]
        ]

      }
    ]
  }


  if (timeDimension) {
    query.timeDimensions = [
      {
        "dimension": timeDimension, //"CustomersTotal.cancellation",
        "dateRange": timeRange
      }
    ]
  }
  if(order) {
    query.order = order
    
  }
  return query;
}

export const customers_lost_period = (compareRange, field) => {
  return {
    "measures": [
      field
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersTotal.cancellation",
        "compareDateRange": compareRange
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
    ]
  }
}
export const customers_lost_period_w_granularity_kindofcount = (dateRange, granularity, field) => {
  return {
    "measures": [field
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersTotal.cancellation",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
    ]
  }
}

export const customers_actives_start = (start_month_previous, start_month_prior) => {

  return {
    "measures": [
      "CustomersTotal.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersTotal.created_at",
        "compareDateRange": [[process.env.REACT_APP_REFERENCE_START, start_month_previous], [process.env.REACT_APP_REFERENCE_START, start_month_prior]]
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "CustomersTotal.cancellation",
        "operator": "notSet"
      }
    ]
  }
}
export const customers_actives_start_parameters = (dateRange, granularity) => {
  return {
    "measures": [
      "CustomersTotal.cumulativeCount"
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersTotal.created_at",
        "dateRange": dateRange,
        "granularity": granularity
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "CustomersTotal.cancellation",
        "operator": "notSet"
      }
    ]
  }
}
export const customers_lost_period_monthly = (compareRange) => {
  return {
    "measures": [
      "CustomersTotal.count"
    ],
    "timeDimensions": [
      {
        "dimension": "CustomersTotal.created_at",
        "granularity": "quarter",
        "compareDateRange": compareRange
      }
    ],
    "order": {},
    "dimensions": [],
    "filters": [
      {
        "member": "CustomersTotal.cancellation",
        "operator": "set"
      }
    ]
  }
}
export const customer_free_plan_period = (dateRange) => {
  let date_start = new Date(dateRange[0])
  date_start.setUTCDate(0)
  const date_startFree = date_start.toISOString()
  return {
    "measures": [
      "FreeConversion.count"
    ],
    "timeDimensions": [
      {
        "dimension": "FreeConversion.created_at",
        "dateRange": [date_startFree, dateRange[1]]
      }
    ],
    "order": {},
    "filters": []
  }
}
export const customer_free_plan_converted_period = (dateRange) => {
  let date_start = new Date(dateRange[0])
  date_start.setUTCDate(0)
  const date_startFree = date_start.toISOString()

  return {
    "measures": [
      "FreeConversion.count"
    ],
    "timeDimensions": [
      {
        "dimension": "FreeConversion.created_at",
        "dateRange": [date_startFree, dateRange[1]]
      }
    ],
    "order": {},
    "filters": [
      {
        "member": "FreeConversion.subscriptionplan",
        "operator": "equals",
        "values": [
          "1"
        ]
      }
    ]
  }
}

export default customers_total;
