
//Grouping and Descriptio for Use Cases 
export const useCases = {

    "margin":["1","2","5","6","7","8","13","15","22","23","25","26","20","29","30","32","33","34","35","90","91","92","9001","240","270","275","276","280"],

    "revenue":["1","2","5","6","7","8","13","15","22","23","25","26"],

    "storecredit":["2","6","10","13","21","22","23","24","26","29","30","32","33"],

    "activeUser":["1","2","3","5","6","7","8","9","10","11","12","13","15","16","17", "18","19","24","25","26","27","29","32"],

    "description":{
        1 : "DNS-01 - Monthly Plan Subscribe or Renew w/ Cash",
        2 : "DNS-02 - Monthly Plan Subscribe or Renew w/ Store Credit",
        3 : "DNS-03 - Monthly Plan Subscribe or Renew w/ Mixed",
        4 : "DNS-04 - Monthly Plan Unsubscribe",
        5 : "DNS-05 - Monthly Plan Upgrade w/ Cash",
        6 : "DNS-06 - Monthly Plan Upgrade w/ Store Credit",
        7 : "DNS-07 - Monthly Plan Upgrade to Higher Monthly",
        8 : "DNS-08 - Monthly Plan Downgrade to Higher Monthly",
        9 : "DNS-09 - Convert to Yearly = Buy Yearly",
        10 : "DNS-10 - Yearly Plan Subscribe or Renew w/ Cash",
        11 : "DNS-11 - Yearly Plan Subscribe or Renew w/ Store Credit",
        12 : "DNS-12 - Yearly Plan Subscribe or Renew w/ Mixed Payment",
        13 : "DNS-13 - Yearly Plan Provide Period Service",
        14 : "DNS-14 - Yearly Plan Unsubscribe",
        15 : "DNS-15 - Upgrade to Higher Yearly w/ Cash",
        16 : "DNS-16 - Upgrade to Higher Yearly w/ Store Credit",
        17 : "DNS-17 - Yearly Plan Upgrade to Higher Yearly w/ Mixed",
        18 : "DNS-18 - Yearly Plan Downgrade to Lower Yearly",
        19 : "DNS-19 - Convert to Monthly",
        20 : "DNS-20 - Cash Refund from Revenue",
        21 : "DNS-21 - Cash Refund from Store Credit",
        22 : "DNS-22 - Store Credit Refund from Revenue",
        23 : "DNS-23 - Store Credit Write-Off",
        24 : "DNS-24 - Store Credit Purchase",
        25 : "DNS-25 - Products Purchase w/ Cash",
        26 : "DNS-26 - Products Purchase w/ Store Credit",
        27 : "DNS-27 - Products Purchease w/ Mixed Payment",
        28 : "DNS-28 - Products Refund",
        29 : "DNS-29 - Store Credit Promo Give-Away",
        30 : "DNS-30 - Store Credit Promo Write-Off",
        31 : "DNS-31 - Store Credit Balance Transfer from Aetrion",
        32 : "DNS-32 - Store Credit Increase on Stripe Proration",
        33 : "DNS-33 - Store Credit Decrease on Stripe Proration",
        34 : "DNS-34 - Chargeback = Stripe Dispute Open",
        35 : "DNS-35 - Chargeback Reversal = Stripe Dispute Won",
        36 : "DNS-36 - Dispute Lost",
        90 : "DNS-90 - Cash Adjustment (Increase)",
        91 : "DNS-91 - Cash Adjustmenr (Deacrease)",
        92 : "DNS-92 - Stripe Transaction Fee",
        190 : "DNS-93 - Revenue Adjustment",
    },
    "description_short":{
        1 : "DNS-01",
        2 : "DNS-02",
        3 : "DNS-03",
        4 : "DNS-04",
        5 : "DNS-05",
        6 : "DNS-06",
        7 : "DNS-07",
        8 : "DNS-08",
        9 : "DNS-09",
        10 : "DNS-10",
        11 : "DNS-11",
        12 : "DNS-12",
        13 : "DNS-13",
        14 : "DNS-14",
        15 : "DNS-15",
        16 : "DNS-16",
        17 : "DNS-17",
        18 : "DNS-18",
        19 : "DNS-19",
        20 : "DNS-20",
        21 : "DNS-21",
        22 : "DNS-22",
        23 : "DNS-23",
        24 : "DNS-24",
        25 : "DNS-25",
        26 : "DNS-26",
        27 : "DNS-27",
        28 : "DNS-28",
        29 : "DNS-29",
        30 : "DNS-30",
        31 : "DNS-31",
        32 : "DNS-32",
        33 : "DNS-33",
        34 : "DNS-34",
        35 : "DNS-35",
        36 : "DNS-36",
        90 : "DNS-90",
        91 : "DNS-91",
        92 : "DNS-92",
        190 : "DNS-93",
    },
    "onlyText":{
      1 : "Monthly Plan Subscribe or Renew w/ Cash",
      2 : "Monthly Plan Subscribe or Renew w/ Store Credit",
      3 : "Monthly Plan Subscribe or Renew w/ Mixed",
      4 : "Monthly Plan Unsubscribe",
      5 : "Monthly Plan Upgrade w/ Cash",
      6 : "Monthly Plan Upgrade w/ Store Credit",
      7 : "Monthly Plan Upgrade to Higher Monthly",
      8 : "Monthly Plan Downgrade to Higher Monthly",
      9 : "Convert to Yearly = Buy Yearly",
      10 : "Yearly Plan Subscribe or Renew w/ Cash",
      11 : "Yearly Plan Subscribe or Renew w/ Store Credit",
      12 : "Yearly Plan Subscribe or Renew w/ Mixed Payment",
      13 : "Yearly Plan Provide Period Service",
      14 : "Yearly Plan Unsubscribe",
      15 : "Upgrade to Higher Yearly w/ Cash",
      16 : "Upgrade to Higher Yearly w/ Store Credit",
      17 : "Yearly Plan Upgrade to Higher Yearly w/ Mixed",
      18 : "Yearly Plan Downgrade to Lower Yearly",
      19 : "Convert to Monthly",
      20 : "Cash Refund from Revenue",
      21 : "Cash Refund from Store Credit",
      22 : "Store Credit Refund from Revenue",
      23 : "Store Credit Write-Off",
      24 : "Store Credit Purchase",
      25 : "Products Purchase w/ Cash",
      26 : "Products Purchase w/ Store Credit",
      27 : "Products Purchease w/ Mixed Payment",
      28 : "Products Refund",
      29 : "Store Credit Promo Give-Away",
      30 : "Store Credit Promo Write-Off",
      31 : "Store Credit Balance Transfer from Aetrion",
      32 : "Store Credit Increase on Stripe Proration",
      33 : "Store Credit Decrease on Stripe Proration",
      34 : "Chargeback = Stripe Dispute Open",
      35 : "Chargeback Reversal = Stripe Dispute Won",
      36 : "Dispute Lost",
      90 : "Cash Adjustment (Increase)",
      91 : "Cash Adjustmenr (Deacrease)",
      92 : "Stripe Transaction Fee",
      190 : "Revenue Adjustment",
  },

}